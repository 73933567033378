import React from 'react';
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import { LocaleTypes } from '../../data/locales';
import ConditionTermsContent from '../../components/ConditionTerms/ConditionTermsContent/ConditionTermsContent';
import ConditionTermsHero from '../../components/ConditionTerms/ConditionTermsHero/ConditionTermsHero';

interface ConditionTermsProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const ConditionTerms: React.FC<ConditionTermsProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="legal.title" />

      <ConditionTermsHero>
        <h1><FormattedMessage id="condition_terms.hero.title"/></h1>
        {/* <p className="lead"><FormattedMessage id="legal.hero.subtitle"/></p> */}
      </ConditionTermsHero>
      <ConditionTermsContent pathContext={pathContext} />
      <Footer theme="gray" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default ConditionTerms;
