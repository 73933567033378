import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { LocaleTypes } from '../../../data/locales';
import { es_top, es_bottom, en_top, en_bottom } from './content';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

interface ConditionTermsContentProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
}

const ConditionTermsContent: React.FC<ConditionTermsContentProps> = ({pathContext}) => {

  return (
    <section className="py-layout-4">
      <div className="container">
        <div className="row">
          <h3 className="text-primary">
            <FormattedMessage id="condition_terms.content.title" />
          </h3>
          <section>
            {
              pathContext.localeCode === 'es' ?
              (<p> 
Estos Términos y Condiciones de Uso (&quot;T&C&quot;) constituyen, junto con el Acuerdo Comercial o Contrato de 
Servicios suscrito, un contrato para la prestación de servicios de aceptación de pagos (“Servicios”) y regulan 
la relación entre la persona natural o jurídica (&quot;Cliente&quot;) que utiliza la Plataforma (según se define más 
adelante) para efectos de vender sus productos y/o servicios (&quot;Contrato de Servicios&quot;) y/o, en caso de ser 
aplicable, los Terceros Vinculados y las sociedades controladas directa o indirectamente por Kushki (&quot;Kushki&quot;). 
Salvo pacto distinto, el Cliente contratará los Servicios objeto de los presentes T&C con la filial de Kushki 
constituida en el país en que el Cliente utilice los Servicios, en cuyo caso serán también aplicables los términos 
y condiciones especiales aplicables por país, a los cuales podrá acceder aquí: 
              </p>)
              : 
              (
                <p>
                  These Terms and Condi.ons of Use (“T&C’s”), together with the executed Commercial Agreement or Services
Agreement, cons.tute an agreement for the provision of payment acceptance services (“Services”) and
govern the rela.onship between the natural or legal person (“Client”) using the PlaForm (as defined below)
for the purpose of selling its products and/or services (“Services Agreement”) and/or, if applicable, the Related
Third Par.es and the companies directly or indirectly controlled by Kushki (“Kushki”). Unless otherwise
agreed, the Client shall contract the Services under these T&C’s with the Kushki subsidiary incorporated in the
country where the Client uses the Services, which shall also be subject to the specific terms and condi.ons
applicable by country, which can be accessed here:
                </p>
              )
            }
          </section>

          <div className="col-md-6 col-12">
            <LocalizedLink to="/terms-and-conditions/chile">
              Chile
            </LocalizedLink>
          </div>
          <div className="col-md-6 col-12">
            <LocalizedLink to="/terms-and-conditions/ecuador">
              Ecuador
            </LocalizedLink>
          </div>
          <div className="col-md-6 col-12">
            <LocalizedLink to="/terms-and-conditions/colombia">
              Colombia
            </LocalizedLink>
          </div>
          <div className="col-md-6 col-12">
            <LocalizedLink to="/terms-and-conditions/mexico">
              México
            </LocalizedLink>
          </div>
          <div className="col-md-6 col-12">
            <LocalizedLink to="/terms-and-conditions/peru">
              Perú
            </LocalizedLink>
          </div>

          <div
            className="markdown-content pb-7"
            dangerouslySetInnerHTML={{ __html: pathContext.localeCode === 'es' ? es_bottom : en_bottom }}
          />
          
        </div>
      </div>
    </section>
  )
}

export default ConditionTermsContent